export const businessIndustryOptions = [
    {
      "name": "sole_proprietorship_owned_by_uae_citizen",
      "value": "Sole proprietorship owned by a UAE citizen"
    },
    {
      "name": "sole_proprietorship_owned_by_gcc_citizen",
      "value": "Sole proprietorship owned by a GCC citizen"
    },
    {
      "name": "limited_liability_company",
      "value": "A company with limited liability"
    },
    {
      "name": "one_man_company",
      "value": "A company owned and operated by a single individual"
    },
    {
      "name": "general_partnership",
      "value": "A partnership with general partners who share responsibilities"
    },
    {
      "name": "private_joint_stock_company",
      "value": "A privately held joint-stock company"
    },
    {
      "name": "public_joint_stock_company",
      "value": "A publicly traded joint-stock company"
    }
  ]
  

export const licenseAuthorityOptions = [
    {
      "name": "department_of_economic_development_dubai",
      "value": "Department of Economic Development, Dubai"
    },
    {
      "name": "abu_dhabi_department_of_economic_development",
      "value": "Abu Dhabi Department of Economic Development"
    },
    {
      "name": "department_of_economic_development_sharjah",
      "value": "Department of Economic Development, Sharjah"
    },
    {
      "name": "department_of_economic_development_ajman",
      "value": "Department of Economic Development, Ajman"
    },
    {
      "name": "department_of_economic_development_ras_al_khaimah",
      "value": "Department of Economic Development, Ras Al Khaimah"
    },
    {
      "name": "department_of_economic_development_fujairah",
      "value": "Department of Economic Development, Fujairah"
    }
  ]
  
  export const vendorTypeOptions =[
    {
      "name": "supplier",
      "value": "Supplier"
    },
    {
      "name": "buyer",
      "value": "Buyer"
    },
  ]
export const emiratesOptions =[
    {
      "name": "abu_dhabi",
      "value": "Abu Dhabi"
    },
    {
      "name": "dubai",
      "value": "Dubai"
    },
    {
      "name": "sharjah",
      "value": "Sharjah"
    },
    {
      "name": "ajman",
      "value": "Ajman"
    },
    {
      "name": "umm_al_quwain",
      "value": "Umm Al Quwain"
    },
    {
      "name": "ras_al_khaimah",
      "value": "Ras Al Khaimah"
    },
    {
      "name": "fujairah",
      "value": "Fujairah"
    }
  ]
  

export const titleAtCompanyOptions =[
    {
      "name": "director",
      "value": "A person who is in charge of managing the company at the highest level"
    },
    {
      "name": "partner",
      "value": "A co-owner of a business, sharing in profits and responsibilities"
    },
    {
      "name": "sole_proprietor",
      "value": "The sole owner and operator of a business"
    },
    {
      "name": "ceo",
      "value": "Chief Executive Officer, the highest-ranking person in a company"
    },
    {
      "name": "cfo",
      "value": "Chief Financial Officer, responsible for managing the company's finances"
    }
  ]
  

  export const roleAtBusinessOptions =[
    {
      "name": "co_borrower",
      "value": "A person who shares the responsibility of repaying a loan with another borrower"
    },
    {
      "name": "guarantor",
      "value": "A person who guarantees to pay back a loan if the primary borrower defaults"
    },
    {
      "name": "key_employee",
      "value": "An essential employee who plays a crucial role in the success of a business"
    },
    {
      "name": "owner",
      "value": "A person who owns a business or a significant portion of it"
    },
    {
      "name": "business_signatory",
      "value": "A person authorized to sign documents on behalf of the business"
    },
    {
      "name": "power_of_attorney",
      "value": "A person legally authorized to act on behalf of another in legal or financial matters"
    }
  ]
  

  export const formatDate= (date:Date) =>{
    return date?.toISOString()?.split("T")[0]
  }